@import "assets/fonts/Pretendard/font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --scrollbar-width: 0px;
  font-family: Pretendard, Inter, system-ui, Avenir, Helvetica, Arial,
    sans-serif;
  font-size: 10px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e1e1e;
}

nav {
  transition: ease 0.4s;
  &--scrolled {
    height: 40px;
  }
}

.pc-root {
  @apply w-full min-w-[1400px] bg-white;
}

.mobile-root {
  @apply w-full max-w-[780px] mx-auto bg-white;
}

/* 흐림 애니메이션 */
.fade-animation {
  position: relative;
  opacity: 0;
  transition: opacity 1s linear;
}
.fade-in {
  transition: opacity 1s linear;
  opacity: 1;
}

/* 배경 이미지 투명도 조절 */
.background-blur {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
}

.background-blur::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url("/public/img/solution/bg.webp");
  background-size: cover;
  opacity: 0.1;
}

@layer text {
  .big-text {
    @apply font-bold tracking-tighter text-8xl;
  }
  .title-h1 {
    @apply text-[4.5rem] font-bold tracking-tighter leading-tight;
  }
  .h1 {
    @apply text-6xl font-bold tracking-tighter;
  }
  .h1-t {
    @apply text-[3.5rem] tracking-tighter font-bold;
  }
  .h1-n {
    @apply text-[3.5rem] tracking-normal font-bold;
  }
  .h2 {
    @apply text-5xl font-bold leading-tight;
  }
  .h3 {
    @apply text-4xl font-bold tracking-tighter;
  }
  .h4 {
    @apply text-3xl font-bold tracking-tighter;
  }
  .h4-t {
    @apply text-[1.685rem] leading-snug tracking-tighter;
  }
  .text-md {
    @apply font-normal text-[1.625rem] leading-tight;
  }
  .h5 {
    @apply text-2xl leading-normal;
  }
  .h5-t {
    @apply font-light text-[1.375rem] leading-normal tracking-tighter;
  }
  .h6 {
    @apply text-xl leading-normal tracking-tighter font-light;
  }
  .sub-thin {
    @apply text-xl font-thin;
  }
  .sub {
    @apply font-light text-[1.375rem] tracking-tighter;
  }
  .sub-thin-sm {
    @apply text-base tracking-tighter font-light;
  }
  /* 예: 모바일용 텍스트 크기 */
  @media screen and (max-width: 768px) {
    .h1 {
      @apply text-3xl font-bold tracking-tighter;
    }
    .h1-t {
      @apply text-[2rem] tracking-tighter font-bold;
    }
    .h1-n {
      @apply text-[2rem] tracking-normal font-bold;
    }
    .h2 {
      @apply text-2xl font-bold leading-tight;
    }
    .title {
      @apply text-[1.625rem] font-bold tracking-tighter leading-tight;
    }
    .h3 {
      @apply text-xl font-bold tracking-tighter;
    }
    .h4 {
      @apply text-lg font-bold tracking-tighter;
    }
    .h4-t {
      @apply text-lg leading-snug tracking-tighter;
    }
    .text-md {
      @apply font-normal text-[1rem] leading-tight;
    }
    .h5 {
      @apply text-base leading-normal;
    }
    .h5-t {
      @apply font-light text-base leading-normal tracking-tighter;
    }
    .h6 {
      @apply text-sm leading-normal tracking-tighter font-light;
    }
    .sub-thin {
      @apply text-xs font-thin;
    }
    .sub {
      @apply text-xs tracking-tighter font-light;
    }
  }
}

@layer alignment {
  .align-center {
    @apply flex items-center justify-center;
  }
  .align-between {
    @apply flex items-center justify-between;
  }
  .align-col-center {
    @apply flex flex-col items-center justify-center;
  }
}

@layer nav {
  .navbar {
    @apply fixed top-0 left-0 z-10 duration-300 ease-in-out delay-300 transform;
  }
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.menu-trigger {
  position: relative;
  width: 36px;
  height: 28px;
}

.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
}

.menu-trigger span:nth-of-type(1) {
  top: 0;
}

.menu-trigger span:nth-of-type(2) {
  top: 12px;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

/* 2th bar 사라지고,  1st 3rd bar 회전하며 X  */

.menu-trigger.active span:nth-of-type(1) {
  transform: translateY(12.3px) rotate(-45deg);
}

.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.menu-trigger.active span:nth-of-type(3) {
  transform: translateY(-12.3px) rotate(45deg);
}
