@charset "utf-8";

/* font-face */
@font-face {
  font-weight: 100;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-Thin.eot");
  src: url("../Pretendard/Pretendard-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-Thin.woff2") format("woff2"),
    url("../Pretendard/Pretendard-Thin.woff") format("woff"),
    url("../Pretendard/Pretendard-Thin.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 200;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-ExtraLight.eot");
  src: url("../Pretendard/Pretendard-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-ExtraLight.woff2") format("woff2"),
    url("../Pretendard/Pretendard-ExtraLight.woff") format("woff"),
    url("../Pretendard/Pretendard-ExtraLight.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 300;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-Light.eot");
  src: url("../Pretendard/Pretendard-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-Light.woff2") format("woff2"),
    url("../Pretendard/Pretendard-Light.woff") format("woff"),
    url("../Pretendard/Pretendard-Light.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 400;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-Regular.eot");
  src: url("../Pretendard/Pretendard-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-Regular.woff2") format("woff2"),
    url("../Pretendard/Pretendard-Regular.woff") format("woff"),
    url("../Pretendard/Pretendard-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 500;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-Medium.eot");
  src: url("../Pretendard/Pretendard-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-Medium.woff2") format("woff2"),
    url("../Pretendard/Pretendard-Medium.woff") format("woff"),
    url("../Pretendard/Pretendard-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 600;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-SemiBold.eot");
  src: url("../Pretendard/Pretendard-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-SemiBold.woff2") format("woff2"),
    url("../Pretendard/Pretendard-SemiBold.woff") format("woff"),
    url("../Pretendard/Pretendard-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 700;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-Bold.eot");
  src: url("../Pretendard/Pretendard-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-Bold.woff2") format("woff2"),
    url("../Pretendard/Pretendard-Bold.woff") format("woff"),
    url("../Pretendard/Pretendard-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 800;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-ExtraBold.eot");
  src: url("../Pretendard/Pretendard-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-ExtraBold.woff2") format("woff2"),
    url("../Pretendard/Pretendard-ExtraBold.woff") format("woff"),
    url("../Pretendard/Pretendard-ExtraBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-weight: 900;
  font-family: "Pretendard";
  font-style: normal;
  src: url("../Pretendard/Pretendard-Black.eot");
  src: url("../Pretendard/Pretendard-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../Pretendard/Pretendard-Black.woff2") format("woff2"),
    url("../Pretendard/Pretendard-Black.woff") format("woff"),
    url("../Pretendard/Pretendard-Black.ttf") format("truetype");
  font-display: swap;
}
